import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudy from "../../components/info-site/CaseStudy";

const JewelleryBrand = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="jewelleryCaseTitle" descriptionId="jewelleryCaseDescription" />
            <CaseStudy
              title="jewelleryCaseTitle"
              caseStudy="caseStudy3"
            />
        </Layout>
    );
};

export default JewelleryBrand;